.dark-btn {
 font-family: inherit;
 font-weight: 600;
 border: 3px solid black;
 border-radius: 0.4em;
 box-shadow: 0.1em 0.1em black;
}

.dark-btn:hover {
 transform: translate(-0.05em, -0.05em);
 box-shadow: 0.15em 0.15em black;
}

.dark-btn:active {
 transform: translate(0.05em, 0.05em);
 box-shadow: 0.05em 0.05em black;
}

.shadow-overlay {
  box-shadow: 0 0 0 100vmax rgb(0 0 0 / 0.8);

}
