/* Hamburger Btn Dark Starts */
.cssbuttons-io-button {
 background: #06b6d4;
 color: white;
 font-family: inherit;
 padding: 0.35em;
 padding-left: 1.2em;
 font-size: 18px;
 font-weight: 600;
 border-radius: 0.7em;
 border: none;
 letter-spacing: 0.05em;
 display: flex;
 align-items: center;
 box-shadow: inset 0 0 1.6em -0.6em #06b6d4;
 overflow: hidden;
 position: relative;
 height: 4.3rem;
 width:18rem;
 padding-right: 3.3em;
}

.cssbuttons-io-button .icon {
 background: white;
 margin-left: 1em;
 position: absolute;
 display: flex;
 align-items: center;
 justify-content: center;
 height: 2.8em;
 width: 2.7em;
 border-radius: 0.7em;
 box-shadow: 0.1em 0.1em 0.6em 0.2em #06b6d4;
 right: 0.3em;
 transition: all 0.3s;
}

.cssbuttons-io-button:hover .icon {
 width: calc(100% - 0.6em);
}

.cssbuttons-io-button .icon svg {
 width: 1.1em;
 transition: transform 0.3s;
 color: #06b6d4;
}

.cssbuttons-io-button:hover .icon svg {
 transform: translateX(0.1em);
}

.cssbuttons-io-button:active .icon {
 transform: scale(0.95);
}
/* Hamburger Btn Dark Ends */

/* Hamburger Btn Light Starts */
.cssbuttons-io-button-light {
 background: #2dd4bf;
 color: white;
 font-family: inherit;
 padding: 0.35em;
 padding-left: 1.2em;
 font-size: 18px;
 font-weight: 600;
 border-radius: 0.7em;
 border: none;
 letter-spacing: 0.05em;
 display: flex;
 align-items: center;
 box-shadow: inset 0 0 1.6em -0.6em #2dd4bf;
 overflow: hidden;
 position: relative;
 height: 4.3rem;
 width:18rem;
 padding-right: 3.3em;
}

.cssbuttons-io-button-light .icon-light {
 background: white;
 margin-left: 1em;
 position: absolute;
 display: flex;
 align-items: center;
 justify-content: center;
 height: 2.8em;
 width: 2.7em;
 border-radius: 0.7em;
 box-shadow: 0.1em 0.1em 0.6em 0.2em #2dd4bf;
 right: 0.3em;
 transition: all 0.3s;
}

.cssbuttons-io-button-light:hover .icon-light {
 width: calc(100% - 0.6em);
}

.cssbuttons-io-button-light .icon-light svg {
 width: 1.1em;
 transition: transform 0.3s;
 color: #2dd4bf;
}

.cssbuttons-io-button-light:hover .icon-light svg {
 transform: translateX(0.1em);
}

.cssbuttons-io-button-light:active .icon-light {
 transform: scale(0.95);
}
/* Hamburger Btn Light Ends */


