.notification-item {
    animation: SlideLeft 0.6s;
    animation-fill-mode: forwards;
}

.notification-exit {
    animation: SlideRight 0.6s;
    animation-fill-mode: forwards; 
}

@keyframes SlideLeft {
    0% {
        margin-left: 120%;
    }

    100% {
        margin-left: 0;
    }
}

@keyframes SlideRight {
    0% {
        margin-left: 0;
    }

    100% {
        margin-left: 120%;
    }
}
