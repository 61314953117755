@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@layer base {
    body {
      @apply dark:bg-darkBlue dark:text-slate-50 font-sans bg-slate-200 text-slate-900
    }
}

@layer components {

    .error {
      @apply text-xs text-red-500 bg-red-100 border border-red-500 rounded-md p-[6px] my-2 mx-0
    }
    .pages-background {
      @apply h-screen dark:bg-[#36393e] bg-[#e9ecef] overflow-auto;
    }
    .contents-div-container {
      @apply h-screen flex flex-col w-[80%] ml-[4.6rem] sm:ml-[5.5rem] sm:mr-[12rem] lg:ml-[12.7rem] xl:ml-[14rem];
    }
    .contents-page-title {
      @apply text-xl xl:text-2xl font-bold;
    }
    .contents-create-div {
      @apply h-screen flex flex-col
             w-[80%] px-5 pt-10 space-y-3
             ml-[4.6rem]
             sm:ml-[5.5rem] sm:mr-[12rem] sm:p-10 sm:space-y-8 sm:w-[90%]
             lg:ml-[12.7rem] lg:pl-[6rem] lg:p-5 lg:pt-9 lg:space-y-3
             xl:ml-[14rem] xl:p-10 xl:pl-[7rem] xl:space-y-7;
    }
    .dark-light-btn-position {
      @apply fixed z-10 top-[1.2rem] right-[1.8rem];
    }
    .notification-style {
      @apply mb-5 pt-2 z-30 rounded-lg shadow-lg w-[310px] opacity-95 bg-green-600 dark:bg-green-400 overflow-hidden;
    }
    .drag-drop-title {
      @apply text-base font-semibold w-[100%] text-center shadow-lg py-3 rounded-lg;
    }
    .droppable-col {
      @apply flex flex-col w-[100%] rounded-lg shadow-lg lg:min-h-[39rem] xl:min-h-[48rem];
    }
    .droppable-col-small {
      @apply flex flex-col min-h-[20rem] sm:min-h-[25rem] lg:min-h-[39rem] xl:min-h-[48rem] rounded-lg shadow-lg w-[100%];
    }

    .popup-btn {
      @apply duration-100 ease-linear transition-all dark:bg-gray-200 dark:text-slate-900 bg-gray-100 dark:hover:bg-amber-400 hover:bg-cyan-300 py-2 px-6 text-sm w-[100%] mx-auto sm:py-1 sm:w-[100%] sm:ml-0 md:w-[50%] md:text-base lg:w-[23%] lg:text-sm xl:w-[23.5%] xl:text-base;
    }

    .sidebar-icon {
      @apply h-[3.3rem] w-[3.3rem] p-[0.6rem] mx-auto rounded-3xl bg-[#2f3136] text-green-400 hover:rounded-xl transition-all duration-100 ease-linear hover:bg-green-600 hover:text-gray-200 hover:scale-105 cursor-pointer;
    }
    .sidebar-icon-active {
      @apply flex justify-start items-center cursor-pointer rounded-3xl opacity-100 scale-110 duration-200 transition-all ease-linear hover:opacity-100;
    }
    .sidebar-icon-non-active {
      @apply flex justify-start items-center cursor-pointer rounded-3xl dark:opacity-50 opacity-80 scale-95 duration-200 transition-all ease-linear hover:opacity-90;
    }

    .create-span-title {
      @apply font-mono text-sm xl:text-base;
    }
    
    .sidebar-title-lg {
      @apply font-medium duration-200 ease-linear text-base xl:text-lg;
    }

    .sidebar-tooltip {
      @apply absolute w-auto p-2 m-2 min-w-max left-14 rounded-md shadow-md bg-[#202225] text-white text-xs font-bold transition-all duration-200 scale-0 origin-left;
    }

}

.border-radius{
  border-radius: 0% 27% 26% 0% / 10% 47% 52% 10%;
}


/* @Reusable! -- Hamburger Button Starts -- */
.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: relative;
  transition: all 0.25s;
}
.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
position: absolute;
width: 24px;
height: 2px;
top: 0;
left: 0;
transform: rotate(0);
transition: all 0.5s;
}
.hamburger-middle {
transform: translateY(7px);
}
.hamburger-bottom {
transform: translateY(14px);
}
.open {
transform: rotate(90deg);
}
.open .hamburger-top {
transform: rotate(45deg) translateY(6px) translateX(6px);
}
.open .hamburger-middle {
display: none;
}
.open .hamburger-bottom {
transform: rotate(-45deg) translateY(6px) translateX(-6px);
}
/* @Reusable! -- Hamburger Button Ends -- */
