
.account-input-group {
 position: relative;
}

.account-input {

 border-radius: 1rem;
 background: none;
 padding: 1rem;
 font-size: 0.95rem;
 color: #0f172a;
 transition: border 150ms cubic-bezier(0.4,0,0.2,1);
}

.user-label {
 position: absolute;
 left: 15px;
 top:1px;
 pointer-events: none;
 transform: translateY(1rem);
 transition: 150ms cubic-bezier(0.4,0,0.2,1);
}

.account-input:focus, input:valid, textarea:valid {
 outline: none;
 background: #f1f5f9;
 border: 3px solid #4ade80; /* green */
}

.account-input:focus ~ label, input:valid ~ label {
 transform: translateY(-50%) scale(0.8);
 background-color: #212121;
 padding: 0 .18em;
 color: #22d3ee;
}



